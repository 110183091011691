import React, { useEffect, useState } from "react";

import { NavLink, Outlet } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../assets/mainPage/OveitLogo.png";
import Disclaimer from "../components/disclaimer/Disclaimer";
import { useLocation } from "react-router-dom";
import Contact from "../components/contact/Contact";
import style from "./layout.module.scss";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Layout() {
  const [panelOpen, setPanelOpen] = useState(false);
  const [targetSection, setTargetSection] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaperOpen, setIsPaperOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const location = useLocation();
  const backgroundImageUrl = location.pathname === "/" ? "" : "";
  const [navbarOpen, setNavbarOpen] = useState(false);
  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "left",
  };
  const backgroundImageNav = {
    backgroundImage: `url(${""})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "left",
  };
  useEffect(() => {
    setNavbarOpen(navbarOpen);
  }, [navbarOpen]);
  const handleNavItemClick = (event, targetId) => {
    event.preventDefault();
    setTargetSection(targetId);
    setPanelOpen(false);
  };

  // const handleNavItemClick = (event, targetId) => {
  //   // if (navbarOpen === true) {
  //   //   setNavbarOpen(!navbarOpen);
  //   // }

  //   event.preventDefault();
  //   const targetElement = document.getElementById(targetId);
  //   if (targetElement) {
  //     const offset = 150; // Set the offset value
  //     const topOffset = targetElement.getBoundingClientRect().top + window.pageYOffset - offset;
  //     window.scrollTo({ top: topOffset, behavior: 'smooth' });
  //   }

  // };
  useEffect(() => {
    if (targetSection) {
      const targetElement = document.getElementById(targetSection);
      if (targetElement) {
        let offset = window.innerWidth < 768 ? 70 : 150; // Set the offset value
        const topOffset =
          targetElement.getBoundingClientRect().top +
          window.pageYOffset -
          offset;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
        setTargetSection(""); // Reset the target section
      }
    }
  }, [targetSection]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openContact = () => {
    setIsContactOpen(true);
  };

  const closeContact = () => {
    setIsContactOpen(false);
  };
  const openPaper = () => {
    setIsPaperOpen(true);
  };

  const closePaper = () => {
    setIsPaperOpen(false);
  };

  return (
    <div className="overflow-hidden p-2 md:p-xl  " style={backgroundImageStyle}>
      {isModalOpen && (
        <Disclaimer title="Disclaimer" onClose={closeModal}>
          <p className="md:font-bold">No Advice</p>
          <p className="mb-4">
            This Website does not constitute any investment advice, financial
            advice, trading advice, or recommendation made by the issuer, nor by
            its affiliates, executives, agents, advisors, or consultants, nor
            should it be relied upon in connection with any contract or
            purchasina decision
          </p>
          <p className="mb-4">
            WE DO NOT OFFER ANY INVESTMENT OR FINANCIAL ADVICE. ANY REFERENCE
            DISPLAYED IN THIS WEBSITE IS MENTIONED ONLY FOR INFORMATIVE
            PURPOSES.
          </p>
          <p>
            Any approximation, value or estimation available in this Website is
            purely made for informative or explanation purposes and does not
            constitute a promise or guarantee.
          </p>
          <p className="mb-4">
            As a rule of thumb, the digital products presented herein are
            available to and must be accessed only by persons who are in full
            civil capacity according to their national regulation.
          </p>
          <p>
            Considering that all the information mentioned in this Website are
            part of an innovative project, we cannot and do not guarantee the
            success of achieving to develop any of the services or features
            thereby presented, their accuracy, applicability, reliability,
            integrity, performance, or appropriateness. Furthermore, we shall
            not be liable for any loss or damage that may be caused directly or
            indirectly by your use of these contents.
            <br />
            All readers are recommended to exercise a high level of prudence and
            responsibly assume decisions within their own capabilities, on their
            own risk.
          </p>
          <p className="md:font-bold">Not a sale of security</p>
          <p>
            This Website does not constitute a prospectus or financial service
            offering document and is not an offer to sell or a solicitation of
            an offer to buy any security, investment products, regulated
            products, or financial instruments in any jurisdiction.
          </p>
          <p className="mb-4">
            No representations or warranties have been made to the recipient of
            this Website or its advisers as to the accuracy or ty completeness
            of the information, statements, opinions or matters (express or
            implied) arising out of, contained in or derived from this Website
            or any omission from this document or of any other written or oral
            information or opinions provided now or in the future to any
            interested party or their advisers. As envisaged in this Website,
            SOV8 is under development and they are being constantly updated,
            including but not limited to key visual and technical features. If
            and when $OV8 and the corresponding features would be deemed
            completed, they may differ significantly from the description set
            out in this Website.
          </p>

          <p className="md:font-bold">No representation</p>
          <p className="mb-4">
            No representations or warranties have been made to the recipient of
            this Website or its advisers as to the accuracy or ery completeness
            of the information, statements, opinions or matters (express or
            implied) arising out of, contained in or derived from this Website
            or any omission from this document or of any other written or oral
            information or opinions provided now or in the future to any
            interested party or their advisers. As envisaged in this Website,
            SOV8 is under development and they are being constantly updated,
            including but not limited to key visual and technical features. If
            and when $OV8 and the corresponding features would be deemed
            completed, they may differ significantly from the description set
            out in this Website.
          </p>
          <p className="md:font-bold">Possible risks</p>
          <p className="mb-4">
            Purchasing any kind of digital items entails some risks and may lead
            to a loss of the money involved. Prior to purchasing or selling any
            Oveit tokens, as well as other fungible or non-fungible tokens you
            should carefully assess and take into account the risks, including
            those listed in any other relevant documentation. A purchaser should
            not acquire any $OV8 or non-fungible tokens based on speculative or
            investment purposes. Purchasers should only purchase the
            non-fungible tokens if they fully understand the nature thereof.
            Hence, a purchaser shall always priorly accept the inherent risks
            associated with any damage or loss which may thereby occur,
            including as to the use of digital procedures, applications and/or
            assets used in order implement a purchase decision.vents to tourism
            Security risks
          </p>
          <p className="mb-4">
            You shall always consider that this project was conceived as
            futuristic at the publishing date of this Website, being based on
            emerging and specialized or niche technologies. Most of these
            technologies and features have still not been tested enough so that
            reliable lessons could be clearly drawn from practice, and at the
            same time the Oveit ecosystem promotes even more innovative use
            cases for such digital assets. Challenges accordingly assumed, we do
            not promise nor guarantee any results, but merely advise you to
            exercise a high degree of caution, proper research and risk
            assumption whenever you desire, plan or intend to access platforms,
            to acquire digital assets (including Oveit tokens) and/or use any
            services related to the project presented throughout this Website.
          </p>
          <p className="mb-4 md:font-bold">
            PLEASE TAKE INTO CONSIDERATION THAT THE VALUE OF ANY FUNGIBLE OR
            NON-FUNGIBLE TOKEN, AS WELL AS OF ANY CRYPTOCURRENCY ON THE OPEN
            MARKET MAY CHANGE BY +/- 100% EVERY SECOND BY REFERENCE TO THE
            ACQUISITION PRICE OR BY REFERENCE TO ANY PREVIOUS VALUE.
          </p>
        </Disclaimer>
      )}
      {isPaperOpen && (
        <Disclaimer onClose={closePaper} title="Term of use">
          <div class="">
            <p className="mb-4"> OVEIT Token ($OV8) Terms of Use</p>
            <p className="mb-4 "></p>
            <p className="mb-4 md:font-bold">1. Welcome to Oveit!</p>
            <p className="mb-4">
              PLEASE READ THE FOLLOWING TERMS CAREFULLY AS THEY GOVERN YOUR USE
              OF THE OVEIT TOKEN ($OV8)
            </p>
            <p className="mb-4">
              These Oveit Token ($OV8) Terms of Use is entered into between you
              (hereinafter referred to as “you” or “your”), as a Token Holder
              and, on the other side, Oveit (hereinafter referred to as “us” or
              “we”).{" "}
            </p>
            <p className="mb-4">
              By using (in any possible way) our Services, you fully agree that
              you have read, understood, and accepted all the terms and
              conditions stipulated in these Terms of Use (hereinafter referred
              to as ‘’Terms’’).
            </p>
            <p className="mb-4">
              When you decide to access, test or use specific features described
              or pre-available on the Platform, you may be subject to specific
              additional terms and conditions that will be available in the
              corresponding section (if applicable).
            </p>
            <p className="mb-4">
              Accessing and using in any way our Services available on the
              Platform you fully acknowledge to be bounded by these Terms and
              shall form a legal agreement and create a binding contract between
              you and Oveit.
            </p>
            <p className="mb-4 md:font-bold">
              2. Who we are and how to contact us
            </p>
            <p className="mb-4">
              Oveit is a fintech company providing digital solutions and smart
              contract applications for experiential shopping. Its products and
              services form a complex ecosystem of interconnected capabilities
              ranging from ticketing and access control to cashless payments,
              gamified experiences, non-fungible tokens (NFTs) and livestream
              shopping. It serves events, venues and experiences in in-person,
              online or hybrid format.{" "}
            </p>
            <p className="mb-4">
              Oveit launches a groundbreaking NFT ticketing protocol to support
              the long-needed overhaul of the entertainment market. First and
              foremost, blockchain capabilities allow solving the overdue issues
              of fraud and price inflation with ease. Moreover, the Oveit
              solution provides the infrastructure to revamp the whole
              interaction system between the experience providers and their
              audiences with the new-generation smart ticket at its core. With
              over seven years of industry experience, know-how, and proprietary
              technology under the belt, Oveit not only undertakes the problems
              of the existing market but uses it as a starting point to pioneer
              the emerging market of experiential shopping
            </p>
            <p className="mb-4">
              Smart tickets are clearly expected to merge payments with numerous
              other functions, including interactions between customers,
              rewards, and other features called for by the emerging
              experiential shopping market. Moreover, with its sophisticated
              ecosystem, Oveit is already one of this market's pioneers striving
              to tender to its needs as they evolve by the day.
            </p>
            <p className="mb-4">
              To achieve its objectives, Oveit is creating a series of
              technological elements and applications which are going to enliven
              its purposeful ecosystem. In that respect, the Oveit Wallet
              represents the central point of the Oveit ecosystem. This wallet's
              capabilities are conceived as supporting and complementing the
              array of Oveit services: NFT ticketing, POS solution Oveit Pay,
              and Web3 commerce app Streams.live.
            </p>
            <p className="mb-4">
              The Oveit Token ($OV8) lies at the intersection of the different
              components within the Oveit ecosystem, and acts as the backbone of
              the ecosystem’s internal transactions and rewards program.
              Therefore, the Oveit Token ($OV8) would be designed to deliver the
              core functionalities of the ecosystem for both B2B consumers and
              end-consumers of live experiences.{" "}
            </p>
            <p className="mb-4">
              To contact us, please email at contact@oveit.org.
            </p>
            <p className="mb-4 md:font-bold">3. Definitions (a-z)</p>
            <p className="mb-4">
              “Applicable Law” means any law, statute, regulation, code,
              ordinance, norm, court decision, order, decree of the Republic of
              Panama or any other normative or administrative act in the
              Republic of Panama or any other decision enacted by or requirement
              or recommendation issued by a public authority or any
              interpretation or application of the above by a public authority,
              provided that any of the above has a binding effect by itself;
            </p>
            <p className="mb-4">”Authority”</p>
            <p className="mb-4">
              {" "}
              means any statutory governmental, judicial, or other authority or
              any of them or any of their authorized representative;
            </p>
            <p className="mb-4">
              ”Blockchain” is a system of recording information, in a way that
              makes it difficult or impossible to change, hack, or cheat the
              registered data and the system, in general using cryptography and
              being duplicated and distributed across the entire network of
              computer connected on the blockchain;
            </p>
            <p className="mb-4">”Cases of force majeure”</p>
            <p className="mb-4"></p>
            <p className="mb-4"></p>
            <p className="mb-4"></p>
            <p className="mb-4"> </p>
            <p className="mb-4">
              {" "}
              represents extraordinary events or circumstances which neither
              Party could have foreseen or prevented by reasonable means,
              including but not limited to natural disasters, war, revolution,
              uprising civil insurrection, acts of terrorism, expropriation,
              nationalization, nuclear explosion, radioactive or chemical
              contamination or ionizing radiation, except lack of funds, which
              will not be a Force Majeure Case;
            </p>
            <p className="mb-4">
              ”Cryptocurrencies” means a digital currency in which transactions
              are verified and records maintained by a decentralized system
              using cryptography named blockchain, rather than by a centralized
              authority, including but not limited to the Oveit Token ($OV8);
            </p>
            <p className="mb-4">
              “Digital Wallet” means the technical solution that allows a user
              to store, send or receive Cryptocurrencies or other digital assets
              by using Blockchain infrastructure. Each Wallet has a Public
              Address to which is assigned a unique Private Key;
            </p>
            <p className="mb-4">
              “Gas fee” refers to the fees charged by the Blockchain
              infrastructure for performing and validating a transaction;
              generally, fees are charged in the Cryptocurrency used by each
              Blockchain for performing transactions;
            </p>
            <p className="mb-4">
              ”Platform” means any technical infrastructure in which the Oveit
              Token ($OV8) will be integrated by anybody, including but not
              limited to the website (https://oveit.org/) and its subdomains or
              to the mobile applications functioning within the Token’s
              ecosystem;
            </p>
            <p className="mb-4">
              “Private Key” means the sequence of characters associated with a
              Digital Wallet required to perform any operation with respect to
              the cryptocurrency therein. The Private Key offers the holder the
              unlimited access to the Digital Wallet (including to its
              contents). The Private Key is known only to the Token Holder and
              must never be disclosed to any another person;
            </p>
            <p className="mb-4">
              ”Public Address” means the sequence of characters (letters and
              numbers) that identifies a Digital Wallet, respectively the
              address to which a Cryptocurrency can be sent for depositing it to
              the corresponding Wallet;
            </p>
            <p className="mb-4">
              “Recovery Phrase” means the specific sequence of words generated
              by the Blockchain infrastructure, when configuring a Digital
              Wallet allowing the user the possibility to re-access/re-configure
              their Digital Wallet, with the observance of the conditions
              imposed by the Digital Wallet service provider. The Token Holder
              is the only one who knows the words of the Recovery Phrase and
              their order;
            </p>
            <p className="mb-4">
              ”Oveit” it is a reference to the Oveit Foundation, as the issuer
              of the Oveit Token ($OV8);
            </p>
            <p className="mb-4">”Oveit Token ($OV8)” or ”Token”</p>
            <p className="mb-4"></p>
            <p className="mb-4"></p>
            <p className="mb-4">
              {" "}
              means the Cryptocurrency type ERC-20 issued by Oveit using the
              Polygon or any other partner Blockchain infrastructure which shall
              be able to be transferred on Blockchain and used in the manner and
              according with the functionalities provided or which may be
              available through/on the Platform or any other technical
              infrastructure which will implement it;
            </p>
            <p className="mb-4">
              “Services” any utilities or functionalities of the Oveit Token
              ($OV8) assigned by Oveit both with respect to internal
              transactions and rewards program, i.e. issuing and managing NFT
              ticket sets in an adequate manner for the emerging Web3 commerce;
              event management; or conducting payments by means of the Oveit
              Pay; and staking certain quantities of the Oveit Token ($OV8) as
              an alternative method of obtaining rewards; this shall not include
              other utilities, functionalities, attributes, etc. of the assigned
              by other entities than Oveit;
            </p>
            <p className="mb-4">
              ”Token Holder” means any person who owns or otherwise uses the
              Oveit Token ($OV8).
            </p>
            <p className="mb-4 md:font-bold">4. General Provisions</p>
            <p className="mb-4">
              WE DO NOT OFFER ANY INVESTMENT OR FINANCIAL ADVICE. ANY REFERENCE
              TO INVESTMENTS DISPLAYED ON THE PLATFORM, WHITEPAPER, OR LITEPAPER
              ARE MENTIONED ONLY FOR INFORMATIVE PURPOSES.
            </p>
            <p className="mb-4">
              We do not provide investment or consulting advice of any kind and
              is not responsible for the use or interpretation of information
              available on the Platform (regardless the uploader) or provided
              throughout other relevant means of communication.{" "}
            </p>
            <p className="mb-4">
              All Services are available only to persons who are at least 18
              years of age or are otherwise considered as having full civil
              capacity of their rights according to the applicable national
              regulations.
            </p>
            <p className="mb-4">
              It is incumbent upon all Token Holders to understand and assume
              the risks associated with the use of Cryptocurrencies, including
              Non-Fungible Tokens (NFTs). It is further recommended to exercise
              a high degree of caution and assume responsibility for any
              decisions made in relation to any Cryptocurrencies – including the
              Oveit Token ($OV8), bearing in mind that any actions taken shall
              be at your own risk.
            </p>
            <p className="mb-4">
              Please take into consideration that the value of the Oveit Token
              ($OV8), as any other Cryptocurrency on the open market, may change
              by +/- 100% every second by reference to the acquisition price or
              by reference to any previous value.
            </p>
            <p className="mb-4 md:font-bold">
              5. Oveit Token ($OV8). Special Provisions.
            </p>
            <p className="mb-4">
              Each user understands and fully assumes that (i) any third -
              party, may try to establish partnerships with different technical
              infrastructures for implementing use cases for the Oveit Token
              ($OV8), such as accessing or acquiring services and features, thus
              other terms & conditions may be applicable in such cases and (ii)
              we and/or our affiliates do not guarantee or provide any kind of
              assurances with regard to the value/specific functionality/utility
              of the Oveit Token ($OV8).
            </p>
            <p className="mb-4">
              Under no circumstances, acquiring or owning any Oveit Tokens
              ($OV8) does not involve or implies the purchase of or transfer of
              ownership over shares, securities exchangeable into shares or any
              equivalent in any existing or future public or private company,
              corporation, or other entity in any jurisdiction.
            </p>
            <p className="mb-4">
              Likewise, as a Token Holder you shall not be entitled to receive,
              exercise and/or issue any requests in connection with or from any
              company/activity/revenues/profits or any other similar rights.{" "}
            </p>
            <p className="mb-4">
              To the best of our knowledge, the Oveit Token ($OV8) is not
              intended to serve as, or be used as, a financial instrument (as
              defined under the international and national legislations), and as
              a result, it has not received approval from any international or
              national authority.
            </p>
            <p className="mb-4">
              Whenever during the use of the Services, any applicable legal
              provisions, whether national or international, that may either
              modify, extend, suspend or cease any the legal regime with regard
              to the Cryptocurrency and Blockchain industry that will enter into
              force, as to the relevant jurisdiction for the scope of these
              Terms, the Token Holder acknowledges that we will be required to
              observe and confer full effects to such new legal requirements
              and, if necessary, we will have to amend certain aspects of the
              Services in order to abide by such provisions. Nevertheless,
              should any future provisions of law will determine us to stop
              providing any good or service contemplated herein, or to modify
              the performance of any obligation thus incurred, we shall be hold
              entirely harmless to Token Holder/s under all theories of
              liability for any material damages that the latter may or would
              intend to claim in this connection, inclusively but not limited to
              your ability to use, hold or transfer in any way the Oveit Token
              ($OV8).
            </p>
            <p className="mb-4 md:font-bold">
              6. Prohibition of Use and Commitments
            </p>
            <p className="mb-4">
              Depending on your country of residence, incorporation, or
              registered office, you may not be able to access or use any of the
              Services. It is your responsibility to follow the rules and laws
              in your country of residence and/or country from which you are
              interacting with our Services and/or the Oveit Token ($OV8).
            </p>
            <p className="mb-4">
              As a Token Holder, you represent and warrant that you have not
              been included (directly/indirectly) and have no relationship
              (directly/indirectly) with any person whose citizen or resident of
              a country which is/was included in any trade embargoes or economic
              sanctions list (such as the United Nations Security Council
              sanctions list), the list of specially designated nationals
              maintained by OFAC (The Office of Foreign Assets Control of the
              U.S. Department of the Treasury), the Financial Action Task Force
              (FATF), International Traffic In Arms Regulations (ITAR) or the
              denied persons or entity list of the U.S. Department of Commerce.
              The restrictions herein target the inclusion as a natural/legal
              person on the above-mentioned lists as well as the inclusion of
              the Token Holder’s citizenship or residency state.{" "}
            </p>
            <p className="mb-4">
              We have the right to choose the markets and jurisdictions wherever
              it could be found appropriate to conduct the business, and may
              restrict or refuse, in its discretion, the provision of Services
              and/or your access to the Oveit Token ($OV8) in certain countries
              or regions.
            </p>
            <p className="mb-4">
              The Oveit Token ($OV8) should only be used to access the services
              conceived in relation to the Ecosystem.
            </p>
            <p className="mb-4 md:font-bold">
              7. Oveit Token ($OV8) Value Index
            </p>
            <p className="mb-4">
              Any value of the Oveit Token ($OV8) displayed on the Platform is
              only for reference purposes and under no circumstances shall be
              interpreted as a promise, a pledge or a guarantee of its Value and
              that we are liable or responsible in any way for that value.{" "}
            </p>
            <p className="mb-4">
              We strongly recommend you not to rely on any value indicated and
              to conduct your own research in order to have an accurate and real
              representation of the evaluation of the Oveit Token ($OV8) based
              on objective factors.
            </p>
            <p className="mb-4 md:font-bold">
              8. Technical Partners and Providers and Third Parties
            </p>
            <p className="mb-4">
              Services may occasionally be accessed through separate third-party
              websites or platforms, in which case those Platform's own
              policies, limitations, and terms & conditions will fully apply to
              those services.
            </p>
            <p className="mb-4">
              We have no control over, and assume no responsibility for, the
              content, privacy policies, or practices of any third-party web
              sites or services. You further acknowledge and agree that we are
              not be responsible or liable, directly or indirectly, for any
              damage or loss caused or alleged to be caused by or in connection
              with use of or reliance on any such content, goods or services
              available on or through any such websites or services.
            </p>
            <p className="mb-4">
              Henceforth, under no circumstances should we be considered liable
              or a party to any business relationship/Agreement concluded
              between any person and the third-party providing services in
              connection with the Oveit Tokens ($OV8), even those services
              promoted or displayed on the Platform.{" "}
            </p>
            <p className="mb-4 md:font-bold">9. Campaigns </p>
            <p className="mb-4">
              We may propose or accept to organize different Campaigns for
              different purposes, such as marketing Campaigns (giveaways,
              airdrops, bounty, Token and/or NFTs incentives) or economic
              Campaigns for supporting the integrity of the ecosystem’s economy
              (Oveit Rewards Program).{" "}
            </p>
            <p className="mb-4">
              Campaigns may be directly organized through the Platform, where
              those Terms and other specific Policies will be applicable, or
              through third party’s services, where the Campaigns will be fully
              governed by their terms & conditions.
            </p>
            <p className="mb-4">
              We are not responsible to any advertising campaigns which is not
              directly and fully conducted by us, even if those advertising
              campaigns are displayed on the Platform or can be accessed through
              external links/hyperlinks available in the Platform.{" "}
            </p>
            <p className="mb-4 md:font-bold">10. Identification</p>
            <p className="mb-4">
              While using the Platform and any dedicated services therein, in
              order to comply with the mandatory law provisions, we may require
              the Token Holders to prove their identity by providing the
              personal information or documents such as, but not limited to
              national ID, passport, driving license, live video, proof of
              funds, proof of residence (utility bill) and so on.
            </p>
            <p className="mb-4">
              For example, that information is mandatory in order to verify
              Token Holder’s identity, identify traces of money laundering,
              terrorist financing, fraud, and other financial crimes.{" "}
            </p>
            <p className="mb-4">
              When applicable, all personal data will be collected, used, and
              shared in accordance with the Privacy Policy displayed in the
              corresponding section of the Platform.{" "}
            </p>
            <p className="mb-4">
              Pursuant to the provisions of our terms of service, we reserve the
              right to deny access to the Services, in the event of
              non-compliance with our identification verifications and source of
              funds verification protocols. Furthermore, in the event that such
              non-compliance is discovered during the course of our due
              diligence procedures, we reserve the right to temporarily suspend
              access to the Services, until such time as the requisite
              information is provided and has been deemed sufficient to satisfy
              our know-your-customer and anti-money laundering compliance
              requirements.
            </p>
            <p className="mb-4">
              If there are any grounds for believing that any of the information
              you provided is incorrect, false, outdated, or incomplete, we will
              send you a notice to demand correction, directly delete the
              relevant information, and, as the case may be, terminate all or
              part of the Services we provide for you, including the services
              provided within the Platform. If we are unable to reach you with
              the contact information you provided, you shall be fully liable
              for any loss or expense caused to us during your use of its
              services.
            </p>
            <p className="mb-4 md:font-bold">11. Security</p>
            <p className="mb-4">
              It is explicitly stated that we shall not be held liable for any
              actions or inaction in relation to your Digital Wallets, which may
              include, but are not limited to, the transfer, reversal of
              transfer, or recovery of access to said wallets, which are used to
              store the Oveit Tokens ($OV8) or other Cryptocurrencies.
            </p>
            <p className="mb-4">
              Considering the above, you solely responsible for:
            </p>
            <p className="mb-4">
              • setting up and keeping all your Credentials confidential;
            </p>
            <p className="mb-4">
              • accessing and using the recovery method/the supplementary
              security measures available for any tool used to access your
              Digital Wallets;{" "}
            </p>
            <p className="mb-4">
              • performing the backup of your Wallet and for keeping safe the
              Recovery Phrase;
            </p>
            <p className="mb-4">
              • any action or transfer you or any other person perform in
              connection with your Oveit Tokens ($OV8), Digital Wallet/s or any
              of your accounts (if any);
            </p>
            <p className="mb-4">
              Any action in connection with the Digital Wallet/s and the Oveit
              Tokens ($OV8) shall be deemed to have been made by you.{" "}
            </p>
            <p className="mb-4">
              We shall not be held liable for any loss or consequences caused by
              authorized or unauthorized use of Digital Wallets, Oveit Tokens
              ($OV8), including but not limited to access caused by information
              disclosure, hacking, information release, phishing, phishing, and
              so on.
            </p>
            <p className="mb-4 md:font-bold">12. Restrictions</p>
            <p className="mb-4">
              During the use of our Services, you firmly assert that at any
              point you:
            </p>
            <p className="mb-4">
              • fully comply with the requirements of Applicable Laws and
              regulations, as well as these Terms;
            </p>
            <p className="mb-4">
              • do not violate other person’s rights, public interests, public
              morals, or the legitimate interests of others, including any
              actions that would interfere with, disrupt, negatively affect, or
              prohibit other person from using the Services;
            </p>
            <p className="mb-4">
              • refrain from for any kind of market manipulation in connection
              with the Oveit Token ($OV8) (such as but not limited to pump and
              dump schemes, self-trading, wash trading, quote stuffing, front
              running, and spoofing or layering, regardless of whether
              prohibited by law);
            </p>
            <p className="mb-4">
              • not try to identify any vulnerability of the Platform/Services
              (including the third party integrated), or violate any security or
              authentication measures.{" "}
            </p>
            <p className="mb-4">
              You expressly empowered us to perform any action to identify and
              investigate any violation of these Terms and/or of the Platform’s
              terms & conditions, unilaterally determine whether you have
              violated any provisions and take actions (without your prior
              consent or notice) such as, but not limited to:
            </p>
            <p className="mb-4">
              • blocking and closing your ongoing activities;
            </p>
            <p className="mb-4">
              • freezing your activity and Oveit Tokens ($OV8);
            </p>
            <p className="mb-4">
              • reporting the incident to the competent authorities;
            </p>
            <p className="mb-4">
              • publishing the alleged violations and actions that have been
              taken;
            </p>
            <p className="mb-4">
              • deleting any information, you published that are found to be
              violations.
            </p>
            <p className="mb-4">
              It is strictly forbidden to conduct any action and/or activity
              which:
            </p>
            <p className="mb-4">
              • is prohibited by our Terms, or may direct, or indirect violate
              its provisions;
            </p>
            <p className="mb-4">
              • is related to illegal activities or with the purpose of causing
              harm to another person, regardless the way in which this purpose
              is done or achieved.
            </p>
            <p className="mb-4 md:font-bold">13. Indemnification</p>
            <p className="mb-4">
              You agree to indemnify and hold harmless us, our affiliates,
              contractors, licensors, and their respective directors, officers,
              employees and agents from and against any claims, actions,
              proceedings, investigations, demands, suits, costs, expenses and
              damages (including attorneys’ fees, fines or penalties imposed by
              any regulatory authority) arising out of or related to (i) your
              use of, or conduct in connection with the Oveit Tokens ($OV8),
              (ii) your breach of these Terms, or (iii) your violation of any
              applicable laws, regulation, or rights of any third party during
              your use of the Services.{" "}
            </p>
            <p className="mb-4">
              In such cases, we will have the right, in our sole discretion, to
              control any actions or proceedings and to determine whether we
              wish to settle, and if so, on what terms.
            </p>
            <p className="mb-4 md:font-bold">14. Warranty and Disclaimers</p>
            <p className="mb-4">
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE, ALONG WITH
              OUR PARENT COMPANIES, SUBSIDIARIES, AFFILIATES, OFFICERS,
              DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, PARTNERS, LICENSORS AND
              DISTRIBUTORS DO NOT MAKE ANY REPRESENTATIONS, PROMISES, OR
              WARRANTIES, EXPRESS OR IMPLIED, ABOUT THE SERVICES. WE PROVIDE THE
              SERVICES “AS-IS,” “WITH ALL FAULTS,” AND “AS AVAILABLE”. YOUR USE
              OF THE SERVICES, INCLUDING OUR CONTENT WITHIN THE SERVICES (and
              excluding the content posted by users), IS AT YOUR OWN RISK AND WE
              DO NOT REPRESENT, PROMISE, OR WARRANT THAT THE SERVICES WILL BE
              UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. YOU UNDERSTAND AND
              AGREE THAT NO DATA TRANSMISSION OVER THE INTERNET OR INFORMATION
              STORAGE TECHNOLOGY CAN BE GUARANTEED TO BE SECURE, AND WE
              EXPRESSLY DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED, TO THAT
              EFFECT. WE MAKE NO COMMITMENTS, PROMISES OR WARRANTIES ABOUT THE
              OVEIT TOKENS ($OV8) OR CONTENT LINKED FROM THE SERVICES, THE
              SUPPORT WE PROVIDE FOR THE SERVICES, THE SPECIFIC FUNCTIONS OF THE
              SERVICES, THE SECURITY OF THE SERVICES, OR THE SERVICES’
              RELIABILITY, QUALITY, ACCURACY, AVAILABILITY, OR ABILITY TO MEET
              YOUR NEEDS, PROVIDE CERTAIN OUTPUTS OR ACHIEVE CERTAIN RESULTS.
            </p>
            <p className="mb-4">
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE, ALONG WITH
              OUR PARENT COMPANIES, SUBSIDIARIES, AFFILIATES, OFFICERS,
              DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, PARTNERS, LICENSORS AND
              DISTRIBUTORS DO NOT MAKE ANY REPRESENTATIONS, PROMISES, OR
              WARRANTIES, EXPRESS OR IMPLIED, ABOUT THE OVEIT TOKENS ($OV8)
              (INCLUDING THEIR VALUE), THE SERVICES, OR ANY OTHER AVAILABLE
              FEATURE OR REGARDING THEIR PROFITABILITY, ACTUALLY, ACCURACY,
              USABILITY ETC.{" "}
            </p>
            <p className="mb-4">
              YOU UNDERSTAND AND ASSUME THAT ANY DECISION FOLLOWING OR IN
              CONNECTION WITH THE OVEIT TOKENS ($OV8), THE SERVICES, THE CONTENT
              AVAILABLE ON THE PLATFORM OR ANY OTHER AVAILABLE FEATURES IS BASED
              ON YOUR OWN RESEARCH AND RESPONSIBILITY.{" "}
            </p>
            <p className="mb-4">
              SOME JURISDICTIONS PROVIDE FOR CERTAIN IMPLIED WARRANTIES, SUCH AS
              THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE AND NON-INFRINGEMENT. TO THE FULLEST EXTENT PERMITTED BY
              APPLICABLE LAW, WE DISCLAIM ANY AND ALL IMPLIED OR EXPRESS
              PROMISES OR WARRANTIES ABOUT THE SERVICES.
            </p>
            <p className="mb-4 md:font-bold">15. Liability </p>
            <p className="mb-4">
              To the fullest extent permitted by Applicable Law, you agree and
              understand that we will not be liable for: any indirect, special,
              incidental, consequential, treble or other multiples of damages,
              exemplary or punitive damages arising from or in connection with
              these Terms or your use of the Services/Oveit Tokens ($OV8).{" "}
            </p>
            <p className="mb-4">
              We and our affiliated entities shall not be held liable for any
              loss of profits, revenues, business opportunities, diminution in
              value or any other losses (collectively “Losses”) arising from or
              in connection with these Terms or your use of or access to the
              Services/Oveit Tokens ($OV8), including, but not limited to:{" "}
            </p>
            <p className="mb-4">
              (i) the deletion of, alteration of, mis-delivery of or failure of
              the Oveit Tokens ($OV8) to other Digital Wallets while using the
              Services;{" "}
            </p>
            <p className="mb-4">
              (ii) downloading or sharing of information, including personal
              information, during the use of our Services;{" "}
            </p>
            <p className="mb-4">
              (iii) the unauthorized access to your Digital Wallet/s;{" "}
            </p>
            <p className="mb-4">
              (iv) services provided by third parties in connection with the
              Oveit Tokens ($OV8);{" "}
            </p>
            <p className="mb-4">
              (v) your dealings with or participation in promotional campaigns
              of third - parties found while using our Services.
            </p>
            <p className="mb-4">
              We will not be liable for damages caused by others, the wrongful
              or unlawful actions of third parties, or an act of God. The
              limitations and exclusions in these terms will apply whether or
              not we have been advised of or should have been aware of the
              possibility of any losses arising.
            </p>
            <p className="mb-4">
              To the fullest extent permitted by law and except as otherwise
              stated in these Terms, we are not liable in connection with any
              disputes that arise out of or relate to the herein.
            </p>
            <p className="mb-4 md:font-bold">
              16. We may make changes our Terms
            </p>
            <p className="mb-4">
              We might change, add or remove parts of these Terms at any time
              and in our sole discretion. Every time you wish to use our site,
              please check these terms to ensure you understand the terms that
              apply at that time.
            </p>
            <p className="mb-4">
              We will notify such changes by simply updating the terms on our
              Platform and modifying the [Last revised] date displayed on this
              page.
            </p>
            <p className="mb-4">
              All modifications or changes to these terms will become effective
              upon publication on the Platform or release to users.
            </p>
            <p className="mb-4">
              It is your responsibility to review the amended Terms. Your
              continued use of the Services following the changes to the Terms,
              you accept and agree to the changes, and that all subsequent
              activity performed by you will be subject to the amended Terms.
            </p>
            <p className="mb-4">
              If you have any question regarding or in connection of the
              information mentioned in these Terms, please do not hesitate to do
              directly contact us.{" "}
            </p>
            <p className="mb-4 md:font-bold">
              17. We may transfer this agreement to someone else
            </p>
            <p className="mb-4">
              The transfer of rights and obligations arising from these Terms
              and / or the use of the Platform is subject to our prior written
              consent.
            </p>
            <p className="mb-4">
              You expressly agree that the we may unilaterally transfer all
              rights and obligations that may be stemming from these Terms
              without your consent.
            </p>
            <p className="mb-4 md:font-bold">18. How to complain </p>
            <p className="mb-4">
              If you wish to complain about content uploaded by other users,
              please contact us on contact@oveit.org.{" "}
            </p>
            <p className="mb-4 md:font-bold">19. Security </p>
            <p className="mb-4">
              You must not misuse our site by knowingly introducing viruses,
              trojans, worms, logic bombs or other material that is malicious or
              technologically harmful. You must not attempt to gain unauthorized
              access to our site, the server on which our site is stored, or any
              server, computer or database connected to our site. You must not
              attack our site via a denial-of-service attack or a distributed
              denial-of service attack. By breaching this provision, you would
              commit a criminal offence under the regulation which governs the
              Platform’s activity. We will report any such breach to the
              relevant law enforcement authorities, and we will co-operate with
              those authorities by disclosing your identity to them. In the
              event of such a breach, your right to use our site will cease
              immediately.{" "}
            </p>
            <p className="mb-4 md:font-bold">
              20. Which country's laws apply to any disputes?{" "}
            </p>
            <p className="mb-4">
              To the maximum extent permitted by the Applicable Law, any invalid
              or unenforceable term or provision of these Terms shall not affect
              the validity or enforceability of the remaining terms and
              provisions thereof or the validity or enforceability of the
              offending term or provision in any other situation. To the maximum
              extent permitted by the Applicable Law, you and us, acting in
              good-faith, shall attest the nullity or unenforceability of the
              respective term or provision and shall replace it by a valid or
              enforceable term or provision which most accurately reflects the
              economic and legal purpose of the invalid or unenforceable term or
              provision.{" "}
            </p>
            <p className="mb-4 md:font-bold">
              21. Our trademarks are registered
            </p>
            <p className="mb-4">
              OVEIT is a trademark registered in EU and protected worldwide
              through the notoriety achieved.{" "}
            </p>
            <p className="mb-4">
              You are not permitted to use them without our approval, unless
              they are part of the material you are using as permitted under
              these Terms.
            </p>
            <p className="mb-4"></p>
            <p className="mb-4"></p>
          </div>
        </Disclaimer>
      )}
      {isContactOpen && <Contact onClose={closeContact} />}
      <div>
        <div>
          <div className=" mx-auto">
            <Disclosure as="nav" className="z-60  mx-auto">
              {({ open }) => (
                <>
                  <div className=" mx-auto    ">
                    <div className={`${style.navBar}  flex  items-center   `}>
                      <div className=" flex w-full max-w-[100%] items-center justify-between rounded-20px border-blue p-1 md:border-[4px]  md:p-2 3xl:p-4">
                        <div className="jusitfy-between flex flex-shrink-0">
                          <NavLink to="/" className="flex items-center ">
                            <Disclosure.Button>
                              <div
                                className="flex items-center bg-black pl-4"
                                style={{ backgroundColor: "black" }}
                              >
                                <img
                                  onClick={(e) =>
                                    handleNavItemClick(e, "header")
                                  }
                                  className="mr-5 h-[34px]  w-[92px] border-none focus:bg-black active:bg-black lg:h-[53px] lg:w-[143px]"
                                  src={logo}
                                  alt="Your Company"
                                />
                              </div>
                            </Disclosure.Button>
                          </NavLink>
                        </div>
                        <div className="flex hidden flex-wrap font-stolziMed  text-14px text-white sm:space-x-4 md:flex  lg:text-20px xl:space-x-8 3xl:gap-20">
                          {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}

                          <NavLink
                            className="hover:text-pink focus:text-pink "
                            onClick={(e) =>
                              handleNavItemClick(e, "oveit-ecosystem")
                            }
                          >
                            Oveit Ecosystem
                          </NavLink>
                          <NavLink
                            className="hover:text-pink focus:text-pink "
                            onClick={(e) =>
                              handleNavItemClick(e, "oveit-token")
                            }
                          >
                            Oveit Token
                          </NavLink>
                          <a
                            className="hover:text-pink focus:text-pink "
                            href={"https://docsend.com/view/c2ckr4xuqtvqh7yn"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            White Paper
                          </a>
                          {/* <NavLink

                            className="focus:text-pink hover:text-pink "
                            onClick={(e) => handleNavItemClick(e, 'roadmap')}
                          >
                            Roadmap
                          </NavLink> */}
                          <NavLink
                            className="hover:text-pink focus:text-pink "
                            onClick={openModal}
                          >
                            Disclaimer
                          </NavLink>
                          {/* <NavLink
                          to="/insights"
                          className="text-white inline-flex items-center border-b-2 border-transparent px-2 lg:px-8 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        >
                          INSIGHTS
                        </NavLink> */}
                        </div>
                        <div>
                          <NavLink
                            onClick={openContact}
                            className={
                              style.buton +
                              " hidden rounded-10px border-2 border-white px-2 py-1 text-14px  font-bold text-white md:block lg:text-20px 3xl:px-8 3xl:py-2"
                            }
                          >
                            Contact Us
                          </NavLink>
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          setNavbarOpen(!navbarOpen);
                        }}
                        id="mobileMenu"
                        className=" mr-2 flex items-center justify-end px-2 md:hidden "
                      >
                        {/* Mobile menu button */}
                        <Disclosure.Button
                          id="BAR_BTN"
                          className=" inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition-all hover:text-gray-500 "
                        >
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon
                              className="block h-6 w-6 text-pink"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block h-6 w-6 text-pink"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel
                    style={backgroundImageNav}
                    className="   flex h-screen w-full flex-col items-center justify-center bg-black transition-transform delay-1000 duration-1000 ease-linear md:hidden"
                  >
                    <div className="z-12 relative flex h-[calc(100%-0px)] flex-col justify-center ">
                      <div className="mt-8 flex h-full flex-col items-center justify-center gap-[51px] ">
                        <NavLink
                          onClick={(e) =>
                            handleNavItemClick(e, "oveit-ecosystem")
                          }
                        >
                          <Disclosure.Button
                            className=" text-xl   font-medium text-white "
                            onClick={() => {
                              setNavbarOpen(!navbarOpen);
                            }}
                          >
                            Oveit Ecosystem
                          </Disclosure.Button>
                        </NavLink>

                        <NavLink
                          onClick={(e) => handleNavItemClick(e, "oveit-token")}
                          className="  text-xl  text-white hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                        >
                          <Disclosure.Button
                            onClick={() => {
                              setNavbarOpen(!navbarOpen);
                            }}
                          >
                            Oveit Token
                          </Disclosure.Button>
                        </NavLink>

                        {/* <NavLink
                          onClick={(e) => handleNavItemClick(e, 'roadmap')}
                          className="  text-xl  text-white hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                        >
                          <Disclosure.Button onClick={() => { setNavbarOpen(!navbarOpen) }}>
                            Roadmap
                          </Disclosure.Button>
                        </NavLink> */}

                        <NavLink
                          onClick={openModal}
                          className="  text-xl  text-white hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                        >
                          <Disclosure.Button
                            onClick={() => {
                              setNavbarOpen(!navbarOpen);
                            }}
                          >
                            Disclaimer
                          </Disclosure.Button>
                        </NavLink>

                        <NavLink
                          onClick={openPaper}
                          className="  text-xl  text-white hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                        >
                          {" "}
                          <Disclosure.Button
                            onClick={() => {
                              setNavbarOpen(!navbarOpen);
                            }}
                          >
                            Terms of Use
                          </Disclosure.Button>
                        </NavLink>

                        <NavLink className="  text-xl  text-white hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">
                          <Disclosure.Button
                            onClick={() => {
                              setNavbarOpen(!navbarOpen);
                              window.open(
                                "https://docsend.com/view/c2ckr4xuqtvqh7yn",
                                "_blank"
                              );
                            }}
                          >
                            White Paper
                          </Disclosure.Button>
                        </NavLink>
                      </div>
                      <div className=" relative z-10  mb-[27px] mt-auto flex  flex-col items-center ">
                        <Disclosure.Button
                          className="my-8 w-full rounded-10px border-2 border-white bg-pink py-[6px] text-20px font-bold text-white md:my-0 md:max-w-[161px] md:self-start"
                          onClick={() => {
                            setNavbarOpen(!navbarOpen);
                          }}
                        >
                          <button onClick={openContact}>Contact us</button>
                        </Disclosure.Button>
                      </div>
                      <div className="z-1" id={style.hexagon}></div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          {!navbarOpen && (
            <div className="phoe:px-2 px-1 pt-[80px] ">
              <Outlet />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
